import React, { FC } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Chip, styled } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';

type ChipType = 'verified' | 'unverified' | 'missing';
type CaptionOverride = {
  label: string;
  title: string;
};

const backgroundColorFromChipType = (chipType: ChipType) => {
  switch (chipType) {
    case 'verified':
      return 'rgba(237, 247, 236, 1)';
    case 'unverified':
      return 'rgb(255,246,228,1)';
    case 'missing':
      return 'rgb(274,235,235,1)';
    default:
      return '';
  }
};

const iconColorFromChipType = (chipType: ChipType) => {
  switch (chipType) {
    case 'verified':
      return green[500];
    case 'unverified':
      return orange[500];
    case 'missing':
      return red[500];
    default:
      return '';
  }
};

const StyledChip = styled(Chip, {
  shouldForwardProp: (propName: PropertyKey) =>
    propName !== 'chipType' && propName !== 'iconOnly'
})<{ chipType: ChipType; iconOnly?: boolean }>(({ chipType, iconOnly }) => ({
  '&.MuiChip-root': {
    backgroundColor: iconOnly
      ? 'transparent'
      : backgroundColorFromChipType(chipType),
    fontWeight: 600,
    padding: '0.25rem',
    '& .MuiChip-icon': {
      color: iconColorFromChipType(chipType)
    },
    '& span': iconOnly ? { display: 'none' } : {},
    '& svg': iconOnly ? { margin: 0 } : {}
  }
}));

type EmailAddressVerificationChipProps = {
  chipType: ChipType;
  iconOnly?: boolean;
  textOverride?: Partial<{
    missing: CaptionOverride;
    unverified: CaptionOverride;
    verified: CaptionOverride;
  }>;
};

export const EmailAddressVerificationChip: FC<
  EmailAddressVerificationChipProps
> = ({ chipType, textOverride, iconOnly }) => (
  <>
    {chipType === 'missing' && (
      <StyledChip
        iconOnly={iconOnly}
        chipType={chipType}
        size="small"
        icon={<ErrorOutlineRoundedIcon />}
        label={textOverride?.missing?.label ?? 'Missing email address'}
        title={textOverride?.missing?.title ?? 'Missing email address'}
      />
    )}
    {chipType === 'unverified' && (
      <StyledChip
        iconOnly={iconOnly}
        chipType={chipType}
        size="small"
        icon={<WarningAmberIcon />}
        label={textOverride?.unverified?.label ?? 'Unverified'}
        title={textOverride?.unverified?.title ?? 'Email address not verified'}
      />
    )}
    {chipType === 'verified' && (
      <StyledChip
        iconOnly={iconOnly}
        chipType={chipType}
        size="small"
        icon={<CheckCircleOutlineIcon />}
        label={textOverride?.verified?.label ?? 'Verified'}
        title={textOverride?.verified?.title ?? 'Email address verified'}
      />
    )}
  </>
);
