import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import FlagIcon from '@mui/icons-material/Flag';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Breadcrumbs, Button, Chip, Tab } from '@mui/material';
import { fetchUserData } from 'actions/User/userActions';
import cn from 'classnames';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { UserRoleLabels } from 'enums/UserRoles.enum';
import { UserTabs, UserTabsLabels } from 'enums/UserTabs.enum';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { isTabVisible } from 'utils/helpers/userTabHelpers';
import { Assessments } from 'views/People/Assessments';
import { LearnerAssociations } from 'views/People/Associations/LearnerAssociations';
import { StaffAssociations } from 'views/People/Associations/StaffAssociations';
import { PersonalInformation } from 'views/People/PersonalInformation';
import { SchedulerPage } from 'views/People/Scheduler/SchedulerPage';
import { LearnerTaskLibrary } from 'views/People/TaskLibrary/LearnerTaskLibrary';
import { StaffTaskLibrary } from 'views/People/TaskLibrary/StaffTaskLibrary';
import { TimeSpent } from 'views/People/TimeSpent';

import styles from './UserDetails.module.scss';

export const UserDetails: FC = () => {
  const { slugId, slug, id } = useParams<{
    id: string;
    slugId: string;
    slug: string;
  }>();

  const [activeTab, setActiveTab] = React.useState<UserTabs>(
    UserTabs.PersonalInformation
  );

  const handleChange = (_e: React.SyntheticEvent, newValue: UserTabs) => {
    setActiveTab(newValue);
  };

  const { isError, isFetching, data } = useQuery<User, ApiError>(
    queryKeys.user(id),
    () => fetchUserData(id),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  const { data: organizationData } = useOrganizationBySlug();

  if (!data) {
    return isFetching ? (
      <AbsoluteSpinner loading={isFetching} />
    ) : (
      <EmptyState />
    );
  }

  const { fullName, role, formattedCreatedAt, isLearner, id: userId } = data;

  const breadcrumbs = [
    <NavLink
      key="users"
      to={generatePath(
        isLearner ? Routes.OrganizationLearners : Routes.OrganizationStaff,
        {
          slugId,
          slug
        }
      )}
    >
      {isLearner ? 'Learners' : 'Staff'}
    </NavLink>,
    <div key="user">
      {fullName}{' '}
      <Chip size="small" label={UserRoleLabels[role]} className={styles.chip} />
    </div>
  ];

  return (
    <div className={styles['user-details']}>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div className={styles['additional-info']}>
        <Breadcrumbs
          separator="›"
          aria-label="user breadcrumb"
          className={styles.breadcrumbs}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <div>
          <span className={styles['date-title']}>
            Account created:
            <span className={styles.date}>{` on ${formattedCreatedAt}`}</span>
          </span>
          <If condition={isLearner}>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              target="_blank"
              href={generatePath(Routes.LearnerReport, {
                slug,
                slugId,
                id
              })}
              startIcon={<FlagIcon />}
            >
              Generate report
            </Button>
          </If>
        </div>
      </div>
      <TabContext value={activeTab}>
        <TabList
          scrollButtons
          variant="scrollable"
          aria-label="User tabs"
          className={styles.tabs}
          onChange={handleChange}
        >
          {Object.values(UserTabs).map((value) => (
            <Tab
              key={value}
              label={UserTabsLabels[value]}
              data-testid="user-tab"
              value={value}
              className={cn(
                styles.tab,
                !isTabVisible(role, value as UserTabs) && styles.hidden
              )}
            />
          ))}
        </TabList>
        <TabPanel
          value={UserTabs.PersonalInformation}
          className={styles['tab-panel']}
        >
          <PersonalInformation
            data={data}
            shouldVerifyEmailAddress={!!organizationData?.isCareCoordinatorOrg}
          />
        </TabPanel>
        <TabPanel value={UserTabs.TimeSpent} className={styles['tab-panel']}>
          <TimeSpent userId={userId} />
        </TabPanel>
        <TabPanel value={UserTabs.Associations} className={styles['tab-panel']}>
          {isLearner ? (
            <LearnerAssociations userId={data.id} />
          ) : (
            <StaffAssociations userId={data.id} />
          )}
        </TabPanel>
        <TabPanel value={UserTabs.TaskLibrary} className={styles['tab-panel']}>
          {isLearner ? (
            <LearnerTaskLibrary user={data} />
          ) : (
            <StaffTaskLibrary user={data} />
          )}
        </TabPanel>
        <TabPanel value={UserTabs.Schedule} className={styles['tab-panel']}>
          <SchedulerPage learnerId={data.id} />
        </TabPanel>
        <TabPanel value={UserTabs.Assessments} className={styles['tab-panel']}>
          <Assessments user={data} />
        </TabPanel>
      </TabContext>
    </div>
  );
};
