// src/opentelemetry.js
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import {
  ConsoleSpanExporter,
  SimpleSpanProcessor
} from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';

// Create a provider for web tracing
const provider = new WebTracerProvider();

// Configure an exporter
const exporter = new OTLPTraceExporter({
  url: 'https://otel-dev.centralreach.com/v1/traces' // Avail-Dev US-East-1 Opentel collector
});

// Add a span processor to send spans to the exporter
provider.addSpanProcessor(new SimpleSpanProcessor(exporter));

// Optionally, use the console exporter to see traces in the console
provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));

// Register the provider
provider.register();

// Register instrumentations
registerInstrumentations({
  instrumentations: [
    new XMLHttpRequestInstrumentation(),
    new FetchInstrumentation()
    // add more instrumentations if needed
  ]
});

console.log('OpenTelemetry initialized');
