import React, { FC, useCallback, useState } from 'react';
import { useIsFetching } from 'react-query';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { DataModal } from 'views/People/TaskLibrary/DataModal';

import styles from './StaffActionMenu.module.scss';

interface Props {
  taskId: string;
  taskTitle: string;
}

export const StaffActionMenu: FC<Props> = ({ taskId, taskTitle }) => {
  const [isDataModalOpen, setIsDataModalOpen] = useState(false);
  const { slugId, slug, id } = useParams<{
    slugId: string;
    slug: string;
    id: string;
  }>();
  const history = useHistory();

  const onToggleDataModal = useCallback(() => {
    setIsDataModalOpen((prevIsDataModalOpen) => !prevIsDataModalOpen);
  }, []);

  const onGoToTask = () => {
    history.push(
      generatePath(Routes.StaffTaskDetails, {
        slugId,
        slug,
        userId: id,
        id: taskId
      })
    );
  };

  const isTasksFetching = useIsFetching(queryKeys.tasksList);

  return (
    <>
      <DataModal
        taskId={taskId}
        taskTitle={taskTitle}
        isOpen={isDataModalOpen}
        onCloseModal={onToggleDataModal}
      />
      <div className={styles['staff-action-menu']}>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={onToggleDataModal}
          disabled={!!isTasksFetching}
        >
          Data
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          className={styles.button}
          onClick={onGoToTask}
        >
          Go to task
        </Button>
      </div>
    </>
  );
};
