import React, { FC, useCallback, useState } from 'react';
import { useIsFetching } from 'react-query';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, MenuItem } from '@mui/material';
import { IconMenu } from 'components/IconMenu';
import { If } from 'components/If';
import { Task } from 'entities/Task.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { DataModal } from 'views/People/TaskLibrary/DataModal';
import { MoveToFolderModal } from 'views/People/TaskLibrary/MoveToFolderModal';

import styles from './UserTaskActionMenu.module.scss';

interface Props {
  task: Task;
  onMoveDown: () => void;
  onMoveUp: () => void;
}

export const UserTaskActionMenu: FC<Props> = ({
  task,
  onMoveDown,
  onMoveUp
}) => {
  const [isMoveToFolderModalOpen, setIsMoveToFolderModalOpen] = useState(false);
  const [isDataModalOpen, setIsDataModalOpen] = useState(false);

  const { slugId, slug, id } = useParams<{
    slugId: string;
    slug: string;
    id: string;
  }>();
  const history = useHistory();

  const onGoToTask = () => {
    history.push(
      generatePath(Routes.LearnerTaskDetails, {
        slugId,
        slug,
        userId: id,
        id: task.id
      })
    );
  };

  const onToggleMoveToFolderModal = useCallback(() => {
    setIsMoveToFolderModalOpen(
      (prevIsMoveToFolderModalOpen) => !prevIsMoveToFolderModalOpen
    );
  }, []);

  const onToggleDataModal = useCallback(() => {
    setIsDataModalOpen((prevIsDataModalOpen) => !prevIsDataModalOpen);
  }, []);

  const isTasksFetching = useIsFetching(queryKeys.tasksList);

  return (
    <>
      <DataModal
        taskId={task.id}
        taskTitle={task.title}
        isOpen={isDataModalOpen}
        onCloseModal={onToggleDataModal}
      />
      <MoveToFolderModal
        onCloseModal={onToggleMoveToFolderModal}
        isOpen={isMoveToFolderModalOpen}
        tasks={[task]}
        queryFilter={{
          assignedToIdEq: task.assignedToId
        }}
      />

      <div className={styles.action}>
        <If condition={!task.isFolder}>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={onToggleDataModal}
            className={styles.button}
            disabled={!!isTasksFetching}
          >
            Data
          </Button>
        </If>

        <IconMenu icon={<MoreVertIcon data-testid="menu-icon" />}>
          <If condition={!task.isFolder}>
            <MenuItem onClick={onGoToTask}>Go to task</MenuItem>
          </If>
          <MenuItem disabled={!onMoveUp} onClick={onMoveUp}>
            Move up
          </MenuItem>
          <MenuItem disabled={!onMoveDown} onClick={onMoveDown}>
            Move down
          </MenuItem>
          <If condition={!task.isFolder}>
            <MenuItem onClick={onToggleMoveToFolderModal}>
              Move to folder
            </MenuItem>
          </If>
        </IconMenu>
      </div>
    </>
  );
};
